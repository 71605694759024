import React from 'react';

export function hero() {
    return <section class="hero">
        <div class="container">
            <div class="hero-inner">
                <div class="slogan">
                    <h1>Innovative Data Solutions for Today's Enterprises</h1>
                    {/* enthusiastically maximize future-proof "outside the box" thinking */}
                </div>
            </div>
        </div>
    </section>;
}
