import Link from '@mui/material/Link';

export default function About() {

    return (
        <div>
            <h1>About</h1>
            <p>
                Qualiaworks is a software company that specializes in building web applications.
                We are a small team of developers that work with our clients to build the best possible product.
            </p>
            <p>
                Our team has experience in building applications for a variety of industries.
                We have worked with clients in the financial, medical, and education fields.
            </p>
            <p>
                We are always looking for new clients to work with.
                If you are interested in working with us, please <Link href="/contact">contact us</Link>.
            </p>
        </div>
    );
}