import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Footer from './components/Footer';

import { Outlet, Link } from "react-router-dom";
import { Link as MuiLink } from '@mui/material';
const pages = [
    { name: "About", link: "about" },
    { name: "Contact", link: "contact" },
];

export default function Layout() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <div>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <IconButton href='/'>
                            <Icon>
                                <img src="logo.svg" />
                            </Icon>
                        </IconButton>
                        <Box>
                            <MuiLink href='/' variant='h4' sx={{ color: "black" }}>
                            Qualiaworks
                            </MuiLink>
                        </Box>
                        <Box sx={{
                            marginRight: "10px",
                            marginLeft: "auto"
                        }}>
                            
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            
                                <MuiLink
                                        key={'blog'}
                                        sx={{ m: 2, color: 'white', display: 'block' }}
                                        href={'https://spotchi.github.io/site/'}
                                    >
                                            Blog
                                </MuiLink>
                                {pages.map((page) => (
                                    // <MuiLink 
                                    //     sx={{ m: 2, color: 'white', display: 'block' }} 
                                    // >
                                        <Link
                                            key={page.name}
                                            style={{ color: 'white', display: 'block', padding: "15px"}}
                                            to={page.link}
                                        >                                   
                                            {page.name}
                                        </Link>

                                    // </MuiLink>
                                ))}
                            </Box>
                        </Box>
                        

                        {/* <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Connect">
                                <IconButton href="https://www.linkedin.com/in/quentin-laurent-7970aa88" sx={{ p: 0 }}>
                                    <Avatar sx={{ bgcolor: "rgb(36, 40, 48)", zIndex: 90 }} alt="Connect">
                                        <SvgIcon height="16px" width="16px" viewBox="0 0 512 512">
                                            <path fill="#828282" d="M150.65,100.682c0,27.992-22.508,50.683-50.273,50.683c-27.765,0-50.273-22.691-50.273-50.683
        C50.104,72.691,72.612,50,100.377,50C128.143,50,150.65,72.691,150.65,100.682z M143.294,187.333H58.277V462h85.017V187.333z
        M279.195,187.333h-81.541V462h81.541c0,0,0-101.877,0-144.181c0-38.624,17.779-61.615,51.807-61.615
        c31.268,0,46.289,22.071,46.289,61.615c0,39.545,0,144.181,0,144.181h84.605c0,0,0-100.344,0-173.915
        s-41.689-109.131-99.934-109.131s-82.768,45.369-82.768,45.369V187.333z"/>
                                        </SvgIcon>
                                    </Avatar>
                                </IconButton>
                            </Tooltip>

                        </Box> */}
                    </Toolbar>
                </Container>
            </AppBar>
            <Outlet />
            <Footer />
        </div>

    );
};
