import { List, ListItem, Typography, Container, Link } from "@mui/material";

export default function Contact() {
  return (
    <Container>
      <Typography variant="h3">Contact Information</Typography>
      <Typography variant="h4">Enquiries</Typography>
      <p>For enquiries regarding new projects or collaborations please send me a connection request
        through my <Link href="https://www.linkedin.com/in/quentin-laurent-7970aa88">LinkedIn profile</Link>. 
      </p>
      <Typography variant="h4">Deliveries</Typography>
      <p>
        For packages please deliver at 
      </p>
      <List>
        <ListItem>BeCentral</ListItem>
        <ListItem>10 Cantersteen</ListItem>
        <ListItem>1000, Brussels</ListItem>
      </List>
    </Container>
  );
}
