import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import FactoryIcon from '@mui/icons-material/Factory';
import Fade from 'react-reveal/Fade';
import ProjectCard from '../components/ProjectCard';
import { hero } from './hero';

export default class Home extends React.Component {
    render() {
        return <Container>
            <Container>
                <main>
                    {hero()}
                </main>
            </Container>

            <Fade bottom>
                <section >
                    <Container sx={{ paddingBottom: "40px" }} >
                        <ProjectGrid />
                    </Container>
                </section>

            </Fade>
        </Container>
    }
}

const projects = [
    {
        title: "Data Platform",
        text: 'Buy a Cloud Data Platform solution from \
 a major provider (Azure, Google Cloud) or build your own!',
        image: "Kubernetes_logo.svg"
    },
    {
        title: "Data And AI lifecycle",
        text: 'Extract, Version, and Visualize your Data. Develop AI models \
        that align with your goals and values',
        image: "Microsoft_Azure.svg"
    },
    //     {
    //         title: "AI applied to the industry",
    //         text: 'Leverage our experience in the Telecom \
    //  and Financial services industry',
    //         image: "briefcase-solid.svg"
    //     },
]

function ProjectGrid() {
    return (<Grid container>
        {projects.map((p, i) => (
            <Grid item key={i} xs={12} md={6} lg={4}>
                <ProjectCard title={p.title} text={p.text} image={p.image} />
            </Grid>
        ))}
        <Grid item key={'industry'} xs={12} md={6} lg={4}>
            <Card sx={{ maxWidth: 345, height: "500px" }} className='card'>
                <CardMedia
                    component="img"
                    height="140px"
                    minHeight="140px"
                    sx={{ height: "340px", padding: "40px", opacity: "0.5" }}
                    image={"briefcase-solid.svg"}
                />
                <CardContent>
                    <Typography gutterBottom component="div" variant="h5">AI applied to the industry</Typography>
                    <Typography variant="body2" color="text.secondary">
                        Leverage our experience in the Telecom
                        and Financial services industry
                    </Typography>
                </CardContent>

            </Card>
        </Grid>
    </Grid>);
}