import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

export default function ProjectCard({ title, text, image }) {
    return (<div>
        <Card sx={{maxWidth: 345, height: "500px"}} className='card'>
            <CardMedia
                component="img"
                height="140px"
                minHeight="140px"
                sx={{ height: "340px", "padding": "40px", opacity: "0.5"}}
                image={ image ? image : "logo.svg" }
            />
            <CardContent>
                <Typography gutterBottom component="div" variant="h5">{title}</Typography>
                <Typography variant="body2" color="text.secondary">
                    {text}
                </Typography>
            </CardContent>

        </Card>
    </div>);
}